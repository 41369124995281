<template>
    <base-scheda-attributo titolo="Referenti">
        <h-col v-if="model.referenti && model.referenti.length" cols="11" sm="10">
            <h-row>
                <h-col cols="12" md="6" v-for="(referente, index) in model.referenti" :key="index">
                    <h-row v-for="(item, index) in items" :key="index"
                           style="border: 1px solid #dfdfdf;border-radius: 4px"
                           align="center"
                    >
                        <h-col cols="4">
                            <h-row justify="end">
                                <h-col class="py-0" cols="auto">
                                    <strong>{{ item.label }}</strong>
                                </h-col>
                            </h-row>
                        </h-col>
                        <h-col cols="8" style="border-left: 1px solid #dfdfdf">
                            <h-row>
                                <h-col class="py-0" cols="auto">
                                    <i class="caption" style="word-break: break-all;white-space:pre-wrap;">
                                        {{ referente[item.key] || referente[item.key] === 0 ? referente[item.key] : '-' }}
                                    </i>
                                </h-col>
                            </h-row>
                        </h-col>
                    </h-row>
                </h-col>
            </h-row>
        </h-col>
        <no-data-available v-else type="referenti"></no-data-available>
    </base-scheda-attributo>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseSchedaAttributo from '@/components/aaaProject/scheda/BaseSchedaAttributo';
    import NoDataAvailable from '@/components/aaaGenerics/commons/NoDataAvailable';

    export default {
        name: 'SchedaClienteReferenti',
        components: {HRow, HCol, NoDataAvailable, BaseSchedaAttributo},
        props: {model: Object},
        data: () => ({
            items: [
                {label: 'Nome', key: 'NOME'},
                {label: 'Email', key: 'EMAIL'},
                {label: 'Telefono', key: 'TELEFONO'},
                {label: 'Fax', key: 'FAX'},
                {label: 'Note', key: 'NOTE'},
            ]
        })
    }
</script>

<style scoped>

</style>
