<template>
    <base-scheda-titolo models-name="clienti"
                        :model="model"
                        model-ref="il cliente"
                        :can-edit="$store.state.authModule.utente.CAN_EDIT_CLIENTE"
                        :can-delete="$store.state.authModule.utente.CAN_DELETE_CLIENTE"
                        check-text="Elimina anche tutti i contratti e gli eventi associati"
    ></base-scheda-titolo>
</template>

<script>
    import BaseSchedaTitolo from '@/components/aaaProject/scheda/BaseSchedaTitolo';

    export default {
        name: 'SchedaClienteTitolo',
        components: {BaseSchedaTitolo},
        props: {model: Object}
    }
</script>

<style scoped>

</style>
