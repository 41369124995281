<template>
    <base-scheda-generale :model="model" :items="items"></base-scheda-generale>
</template>

<script>
    import BaseSchedaGenerale from '@/components/aaaProject/scheda/BaseSchedaGenerale';
    import moment from '@/moment';

    export default {
        name: 'SchedaClienteGenerale',
        components: {BaseSchedaGenerale},
        props: {model: Object},
        computed: {
            items: function () {
                return [
                    {label: 'Nome', data: this.model.NOME},
                    {label: 'Codice Cliente', data: this.model.CODICE_CLIENTE},
                    {label: 'Eventi Associati', data: this.model.EV_ALL_COUNT},
                    {label: 'Utenze Associate', data: this.model.UT_COUNT},
                    {
                        label: 'Inizio Contratto',
                        data: this.model.CLC_MIN_INIZIO ? moment.toDate(this.model.CLC_MIN_INIZIO) : '-'
                    },
                    {
                        label: 'Fine Contratto',
                        data: this.model.CLC_MAX_SCADENZA ? moment.toDate(this.model.CLC_MAX_SCADENZA) : '-'
                    },
                    {label: 'Visualizzazioni', data: this.model.VISUALIZZAZIONI},
                    {
                        label: 'Disattivazione Manuale',
                        data: this.model.DATA_DISATTIVAZIONE ? moment.toDate(this.model.DATA_DISATTIVAZIONE) : 'MAI'
                    },
                    {label: 'Contratti Definitivi', data: this.model.CLC_NORM_COUNT},
                    {label: 'Preventivi', data: this.model.CLC_PREV_COUNT},
                    {label: 'XS', data: this.model.CLC_XS_COUNT},
                    {label: 'XS', data: this.model.CLC_XS_COUNT_PREV},
                    {label: 'S', data: this.model.CLC_S_COUNT},
                    {label: 'S', data: this.model.CLC_S_COUNT_PREV},
                    {label: 'M', data: this.model.CLC_M_COUNT},
                    {label: 'M', data: this.model.CLC_M_COUNT_PREV},
                    {label: 'L', data: this.model.CLC_L_COUNT},
                    {label: 'L', data: this.model.CLC_L_COUNT_PREV},
                    {label: 'XL', data: this.model.CLC_XL_COUNT},
                    {label: 'XL', data: this.model.CLC_XL_COUNT_PREV},
                    {label: 'XXL', data: this.model.CLC_XXL_COUNT},
                    {label: 'XXL', data: this.model.CLC_XXL_COUNT_PREV}
                ]
            }
        }
    }
</script>

<style scoped>

</style>
