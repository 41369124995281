<template>
    <h-row class="py-6" justify="center">
        <h-col cols="11" class="elevation-2" style="border-radius: 6px">
            <h-row justify="center" align="center">
                <h-col class="pl-0 pb-4" cols="auto">
                    <h2>{{ titolo }}</h2>
                </h-col>
            </h-row>
            <h-row class="pb-6" justify="center">
                <slot></slot>
            </h-row>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'BaseSchedaAttributo',
        components: {HRow, HCol},
        props: {titolo: String}
    }
</script>

<style scoped>

</style>
