<template>
    <base-scheda models-name="clienti" :schede="schede">

        <template #header="{ model }">
            <scheda-cliente-header :model="model"></scheda-cliente-header>
        </template>

        <template #titolo="{ model }">
            <scheda-cliente-titolo :model="model"></scheda-cliente-titolo>
        </template>

        <template #generale="{ model }">
            <scheda-cliente-generale :model="model"></scheda-cliente-generale>
        </template>

    </base-scheda>
</template>

<script>
    import BaseScheda from '@/components/aaaProject/scheda/BaseScheda';
    import SchedaClienteHeader from '@/components/secure/clienti/components/SchedaClienteHeader';
    import SchedaClienteTitolo from '@/components/secure/clienti/components/SchedaClienteTitolo';
    import SchedaClienteGenerale from '@/components/secure/clienti/components/SchedaClienteGenerale';
    import SchedaClienteReferenti from '@/components/secure/clienti/components/SchedaClienteReferenti';

    export default {
        name: 'SchedaCliente',
        components: {SchedaClienteGenerale, SchedaClienteTitolo, SchedaClienteHeader, BaseScheda},
        data: (_this) => ({
            schede: [
                {nome: 'referenti', component: SchedaClienteReferenti, showIf: _this.showIf}
            ]
        }),
        methods: {
            showIf() {
                return !this.$store.getters.isFromCliente;
            }
        }
    }
</script>

<style scoped>

</style>
